<template>
  <div>
    <button @click="params.change(params.data.name, 1)" class="btn">
      <i class="fal fa-plus"></i>
    </button>
    <button @click="params.change(params.data.name, -1)" class="btn">
      <i class="fal fa-minus"></i>
    </button>
  </div>
</template>

<style lang="scss" scoped>
.btn:first-child {
  padding-right: 0px;
}
i {
  font-size: 26px !important;
}
</style>
