<template>
  <div class="orders-table">
    <div class="adding-line flex align-item-center">
      <custom-select2
          :options="productsOptions"
          v-model="productInfo.id"
          name="id"
          label="Продукт"
          :labelWidth="34"
      />
      <custom-input
          v-model="productInfo.name"
          :labelWidth="34"
          name="productName"
          label="Название"
      />
      <custom-input
          type="number"
          label="Кол-во"
          v-model="productInfo.count"
          name="productCount"
          :labelWidth="34"
          :min="0"
      />
      <button @click="addProduct" class="btn btn-accent">Добавить</button>
    </div>
    <div class="adding-line flex align-item-center">
      <custom-select2
          :options="entitiesOptions"
          v-model="entityInfo.id"
          name="id"
          label="Сущность"
          :labelWidth="34"
      />
      <custom-input
          v-model="entityInfo.name"
          :labelWidth="34"
          name="entityName"
          label="Название"
      />
      <custom-input
          type="number"
          label="Кол-во"
          v-model="entityInfo.count"
          name="entityCount"
          :labelWidth="34"
          :min="0"
      />
      <button @click="addEntity" class="btn btn-accent">Добавить</button>
    </div>
    <div class="adding-line flex align-item-center">
      <custom-select2
          :options="courseBitrixOptions"
          v-model="courseBitrixInfo.id"
          name="id"
          label="Курсы Bitrix"
          :labelWidth="34"
      />
      <custom-input
          v-model="courseBitrixInfo.name"
          :labelWidth="34"
          name="courseBitrixName"
          label="Название"
      />
      <custom-input
          type="number"
          label="Кол-во"
          v-model="courseBitrixInfo.count"
          name="courseBitrixCount"
          :labelWidth="34"
          :min="0"
      />
      <button @click="addCourseBitrix" class="btn btn-accent">Добавить</button>
    </div>
    <div class="adding-line flex align-item-center">
      <custom-select2
          :options="courseMoodleOptions"
          v-model="courseMoodleInfo.id"
          name="id"
          label="Курсы Moodle"
          :labelWidth="34"
      />
      <custom-input
          v-model="courseMoodleInfo.name"
          :labelWidth="34"
          name="courseMoodleName"
          label="Название"
      />
      <custom-input
          type="number"
          label="Кол-во"
          v-model="courseMoodleInfo.count"
          name="courseMoodleCount"
          :labelWidth="34"
          :min="0"
      />
      <button @click="addCourseMoodle" class="btn btn-accent">Добавить</button>
    </div>
    <div class="order-table">
      <ag-grid-vue
          :columnDefs="columnDefs.value"
          :rowData="rowData"
          :rowHeight="120"
          :headerHeight="90"
          :enableCellTextSelection="true"
          :ensureDomOrder="true"
          :pagination="true"
          :paginationPageSize="100"
          :defaultColDef="defaultColDef"
          :suppressRowHoverHighlight="true"
          :suppressPaginationPanel="true"
          :suppressRowClickSelection="true"
          :suppressRowTransform="true"
          style="width: 100%"
          domLayout="autoHeight"
          @grid-ready="onGridReady"
      ></ag-grid-vue>
    </div>
  </div>
</template>

<script>
import {computed, onBeforeMount, reactive, ref, watch,} from "@vue/runtime-core";
import VPagination from "@hennge/vue3-pagination";
import {useStore} from "vuex";
import ShortTextRenderer from "@/components/Tables/CellRenderers/ShortTextRenderer.vue";
import DeleteRenderer from "@/components/Tables/CellRenderers/DeleteRenderer.vue";
import {AgGridVue} from "ag-grid-vue3";
import "ag-grid-community/dist/styles/ag-grid.css";
import CustomInput from "@/components/Forms/Fields/CustomInput.vue";
import TablePagination from "@/components/OtherElements/TablePagination.vue";
import CustomSelect2 from "@/components/Forms/Fields/CustomSelect2.vue";
import reportTranslate from "@/mixins/dictionaries/reportTranslate.js";
import {notify} from "@kyvg/vue3-notification";
import ChangeNumberRenderer from "../../../components/Tables/CellRenderers/ChangeNumberRenderer.vue";
import EntityOrderRenderer from "../../../components/Tables/CellRenderers/EntityOrderRenderer.vue";

export default {
  name: "orders-table",
  components: {
    CustomSelect2,
    VPagination,
    AgGridVue,
    ShortTextRenderer,
    CustomInput,
    DeleteRenderer,
    TablePagination,
    ChangeNumberRenderer,
    EntityOrderRenderer,
  },
  props: {
    repurchaseData: {
      type: Array,
      product: null,
      entity: null,
      name: null,
      count: null,
      price: null
    }
  },
  setup(props, context) {
    const store = useStore(),
        gridApi = ref(null);

    const relations = computed(() => store.state.relations.relations),
        productsList = computed(() => store.state.relations.productsList),
        entitiesList = computed(() => store.state.relations.entitiesList);

    const positions = ref([]);
    const productsOptions = ref([]),
        entitiesOptions = ref([]),
        courseBitrixOptions = ref([]),
        courseMoodleOptions = ref([]);

    const productInfo = reactive({
      id: "",
      count: 0,
      productName: "",
      name: "",
      slug: ""
    });
    const entityInfo = reactive({
      id: "",
      entityName: "",
      count: 0,
      name: "",
    });
    const courseBitrixInfo = reactive({
      id: "",
      courseBitrixName: "",
      count: 0,
      name: "",
      source: "",
    });
    const courseMoodleInfo = reactive({
      id: "",
      courseMoodleName: "",
      count: 0,
      name: "",
      source: "",
    });

    const onGridReady = (params) => {
      gridApi.value = params.api;
    };

    const rowData = computed(() => {
      if (props.repurchaseData) {
        positions.value = props.repurchaseData
        context.emit("change", positions.value);
      }
      return positions.value.map((position) => {
        return {
          product: position.product || "-",
          entity: position.entities || position.entity,
          name: position.name,
          count: position.count,
        };
      });
    });

    const deleteItem = (name) => {
      const isPositionIndex = positions.value.findIndex(
          (position) => position.name === name
      );
      positions.value.splice(isPositionIndex, 1);
      context.emit("change", positions.value);
    };

    const changeCount = (name, num) => {
      const isPositionIndex = positions.value.findIndex(
          (position) => position.name === name
      );
      const findedPosition = positions.value[isPositionIndex];
      const newCount = Number(findedPosition.count) + num;
      newCount <= 0
          ? positions.value.splice(isPositionIndex, 1)
          : (positions.value[isPositionIndex] = {
            ...findedPosition,
            count: newCount,
          });
      context.emit("change", positions.value);
    };

    const columnDefs = reactive({
      value: [
        {
          field: "product",
          headerName: "Продукт",
          minWidth: 150,
          wrapText: true,
          cellRenderer: "ShortTextRenderer",
        },
        {
          field: "entity",
          headerName: "Сущность",
          wrapText: true,
          minWidth: 150,
          cellRenderer: "EntityOrderRenderer",
        },
        {
          field: "name",
          headerName: "Название",
          minWidth: 150,
          wrapText: true,
          cellRenderer: "ShortTextRenderer",
        },
        {
          field: "count",
          headerName: "Количество",
          flex: 0,
          minWidth: 150,
          maxWidth: 150,
        },
        {
          field: "",
          headerName: "",
          flex: 0,
          minWidth: 130,
          maxWidth: 130,
          cellRenderer: "ChangeNumberRenderer",
          cellRendererParams: {
            change: changeCount,
          },
        },
        {
          field: "",
          headerName: "",
          flex: 0,
          minWidth: 80,
          maxWidth: 80,
          cellRenderer: "DeleteRenderer",
          cellRendererParams: {
            callback: deleteItem,
          },
        },
      ],
    });
    const defaultColDef = {
      suppressMovable: true,
      flex: 1,
      autoHeight: true,
    };

    const addProduct = () => {
      if (
          !fieldValidation(productInfo.count, productInfo.id, productInfo.name)
      ) {
        return;
      }
      if (checkAndReplaceExist(productInfo.name, productInfo.count)) {
        return;
      }
      const productData = productInfo.id.split("_");
      const productEntities = relations.value
          .filter(
              (relation) =>
                  relation.site_entity_type === "product" &&
                  relation.site_entity_id == productData[1]
          )
          .map((relation) => relation.entity_name);
      const productObject = {
        id: productData[1],
        product: productInfo.productName,
        entities: productEntities,
        name: productInfo.name,
        price: productData[2],
        count: parseInt(productInfo.count, 10),
        slug: productInfo.slug
      };

      positions.value.push(productObject);
      context.emit("change", positions.value);
    };

    const addCourseBitrix = () => {
      if (!fieldValidation(courseBitrixInfo.count, courseBitrixInfo.id, courseBitrixInfo.name)) {
        return;
      }
      if (checkAndReplaceExist(courseBitrixInfo.name, courseBitrixInfo.count)) {
        return;
      }
      const entityObject = {
        id: courseBitrixInfo.id,
        type: Object.keys(reportTranslate).includes(courseBitrixInfo.id)
            ? "test"
            : "course",
        product: null,
        entity: courseBitrixInfo.courseBitrixName,
        name: courseBitrixInfo.name,
        source: courseBitrixInfo.source || null,
        count: parseInt(courseBitrixInfo.count, 10),
      };
      positions.value.push(entityObject);
      context.emit("change", positions.value);
    };
    const addCourseMoodle = () => {
      if (!fieldValidation(courseMoodleInfo.count, courseMoodleInfo.id, courseMoodleInfo.name)) {
        return;
      }
      if (checkAndReplaceExist(courseMoodleInfo.name, courseMoodleInfo.count)) {
        return;
      }
      const entityObject = {
        id: courseMoodleInfo.id,
        type: Object.keys(reportTranslate).includes(courseMoodleInfo.id)
            ? "test"
            : "course",
        product: null,
        entity: courseMoodleInfo.courseMoodleName,
        name: courseMoodleInfo.name,
        source: courseMoodleInfo.source || null,
        count: parseInt(courseMoodleInfo.count, 10),
      };
      positions.value.push(entityObject);
      context.emit("change", positions.value);
    };
    const addEntity = () => {
      if (!fieldValidation(entityInfo.count, entityInfo.id, entityInfo.name)) {
        return;
      }
      if (checkAndReplaceExist(entityInfo.name, entityInfo.count)) {
        return;
      }
      const entityObject = {
        id: entityInfo.id,
        type: Object.keys(reportTranslate).includes(entityInfo.id)
            ? "test"
            : "course",
        product: null,
        entity: entityInfo.entityName,
        name: entityInfo.name,
        count: parseInt(entityInfo.count, 10),
      };
      positions.value.push(entityObject);
      context.emit("change", positions.value);
    };

    const checkAndReplaceExist = (name, count) => {
      const isPositionIndex = positions.value.findIndex(
          (position) => position.name === name
      );
      if (isPositionIndex !== -1) {
        let findedPosition = positions.value[isPositionIndex];
        positions.value[isPositionIndex] = {
          ...findedPosition,
          count: Number(findedPosition.count) + Number(count),
        };
        return true;
      }
      return false;
    };

    const fieldValidation = (count, id, name) => {
      if (!count || !id || !name) {
        notify({
          type: "warning",
          title: "Не все поля заполнены",
        });
        return false;
      }
      if (count <= 0) {
        notify({
          type: "warning",
          title: "Некорректное количество",
        });
        return false;
      }
      return true;
    };



    onBeforeMount(() => {
      store.dispatch("relations/getProductsList").then(() => {
        productsOptions.value = productsList.value.map((product) => {
          return {
            text: product.name,
            id: `${product.name}_${product.id}_${product.price}`,
            slug: product.slug
          };
        });
      });
      store.dispatch("relations/getEntitiesList").then(() => {
        entitiesOptions.value = [
          ...Object.entries(entitiesList.value.tests).map((entity) => ({
            id: entity[0],
            text: entity[1],
          })),
          ...Object.entries(entitiesList.value.reference_profiles).map((entity) => ({
            id: entity[0],
            text: entity[1],
          })),
          ...Object.entries(entitiesList.value.trajectories).map((entity) => ({
            id: entity[0],
            text: entity[1],
          })),
        ];
        courseBitrixOptions.value = [
          ...entitiesList.value.courses.filter(course => course.source === "bitrix")
              .map((entity) => ({
                id: entity.id,
                source: entity.source || null,
                text: entity.title,
              })),
        ];
        courseMoodleOptions.value = [
          ...entitiesList.value.courses.filter(course => course.source === "moodle")
              .map((entity) => ({
                id: entity.id,
                source: entity.source || null,
                text: entity.title,
              })),
        ]
      });
      store.dispatch("relations/getRelations");
    });

    watch(
        () => courseMoodleInfo.id,
        () => {
          const courseMoodleName = courseMoodleOptions.value.find((course) => course.id.toString() === courseMoodleInfo.id).text;
          courseMoodleInfo.name = courseMoodleName;
          courseMoodleInfo.courseMoodleName = courseMoodleName;
          courseMoodleInfo.source = courseMoodleOptions.value.find((course) => course.id.toString() === courseMoodleInfo.id).source;
        }
    );
    watch(
        () => courseBitrixInfo.id,
        () => {
          const courseBitrixName = courseBitrixOptions.value.find((course) => course.id === courseBitrixInfo.id).text;
          courseBitrixInfo.name = courseBitrixName;
          courseBitrixInfo.courseBitrixName = courseBitrixName;
          courseBitrixInfo.source = courseBitrixOptions.value.find((course) => course.id === courseBitrixInfo.id).source;
        }
    );
    watch(
        () => entityInfo.id,
        () => {
          const entityName = entitiesOptions.value.find((entity) => entity.id === entityInfo.id).text;
          entityInfo.name = entityName;
          entityInfo.entityName = entityName;
        }
    );
    watch(
        () => productInfo.id,
        () => {
          const productName = productsOptions.value.find((product) => product.id === productInfo.id).text;
          productInfo.name = productName;
          productInfo.productName = productName;
          const slugData = productsOptions.value.find((product) => product.id === productInfo.id).slug;
          productInfo.slug = slugData;
        }
    );

    return {
      entitiesOptions,
      productsOptions,
      columnDefs,
      rowData,
      defaultColDef,
      gridApi,
      onGridReady,

      positions,

      productInfo,
      entityInfo,

      addProduct,
      addEntity,
      courseBitrixOptions,
      courseBitrixInfo,
      addCourseBitrix,
      courseMoodleOptions,
      courseMoodleInfo,
      addCourseMoodle,
    };
  },
};
</script>

<style lang="scss">
.orders-table {
  .ag-body-viewport {
    min-height: 100px;
  }
}
</style>

<style lang="scss" scoped>
.adding-line {
  border-bottom: 2px solid var(--cream-color);
  padding: 5px 15px;

  .form-group:first-child {
    min-width: 350px;
  }

  .form-group {
    flex-basis: 26%;
    flex-grow: 1;
    margin-right: 10px;
  }

  .form-group:last-of-type {
    max-width: 270px;
  }

  &:last-child {
    border-top: none;
  }

  @media (max-width: 1469px) {
    display: grid;
    grid-template-columns: 50% 50%;

    .btn {
      margin-right: 15px;
    }

    .form-group:last-of-type {
      max-width: 100%;
    }

    .form-group:first-child {
      min-width: 100%;
    }
  }

  @media (max-width: 1145px) {
    grid-template-columns: 100%;
    padding-bottom: 15px;

    .btn {
      margin-top: 15px;
    }
  }
}

.order-table {
  padding: 5px 8px;
}
</style>
