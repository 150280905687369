<template>
  <form action="#" novalidate>
    <div class="inputs display--flex justify-content-center">
      <div class="col1">
        <custom-select-2
          label="Пользователь"
          name="user_id"
          v-model="form.user"
          :options="currentUser"
          :settings="usersAjax"
        />
        <custom-input
          type="number"
          name="crm_id"
          v-model="form.crm_id"
          label="ID в CRM"
        />
      </div>
      <div class="col1">
        <custom-textarea
          v-model="form.comment"
          name="comment"
          horizontal
          :height="95"
          label="Комментарий"
        />
      </div>
    </div>
  </form>
</template>

<script>
import { reactive, ref } from "@vue/reactivity";
import CustomSelect2 from "../../../components/Forms/Fields/CustomSelect2.vue";
import { computed, onMounted, watch } from "@vue/runtime-core";
import CustomInput from "@/components/Forms/Fields/CustomInput";
import { useStore } from "vuex";
import CustomTextarea from "../../../components/Forms/Fields/CustomTextarea.vue";
import useAjaxSelect from "@/mixins/useAjaxSelect";

export default {
  components: { CustomInput, CustomSelect2, CustomTextarea },
  name: "order-form",
  props: {
    repurchaseInfo: {
        user: Object || null,
        crm_id: Number || null,
        comment: String || null
      }
  },
  setup(props, context) {
    const store = useStore();
    const form = reactive({
      user: props.repurchaseInfo?.user ? props.repurchaseInfo.user.id.toString() : null,
      crm_id:  props.repurchaseInfo?.crm_id ? props.repurchaseInfo.crm_id : null,
      comment:  props.repurchaseInfo?.comment ? props.repurchaseInfo.comment : null
    });
    const staffs = computed(() => store.state.users.users);
    let { usersAjax } = useAjaxSelect();
    let currentUser = []
   if(props.repurchaseInfo?.user) {
      currentUser = ref([{id:props.repurchaseInfo?.user.id, text:`${props.repurchaseInfo?.user.last_name} (${props.repurchaseInfo?.user.email})`}])
      context.emit("changeData", form);
    }
    watch(form, () => {
      context.emit("changeData", form);
    });

    return {
      form,
      usersAjax,
      staffs,
      currentUser
    };
  },
};
</script>

<style lang="scss" scoped>
.col1 {
  flex: 1;
}

@media (max-width: 1464px) {
  .inputs {
    flex-direction: column;
  }
}

@media (max-width: 973px) {
  .form-group {
    margin: 15px -15px;
  }
}

@media (max-width: 768px) {
  .form-group {
    margin: 15px -15px;
  }
}

@media (max-width: 425px) {
  .form-group {
    margin: 15px -15px;
  }
}
</style>